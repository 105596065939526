import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  StarOutlinedIcon,
  StarFilledIcon,
  HouseOutlinedIcon,
  HouseFilledIcon,
  ChartOutlinedIcon,
  ChartFilledIcon,
  QuestionOutlinedIcon,
  QuestionFilledIcon,
  DocumentsIcon,
  DocumentsFilledIcon,
  MapIcon,
  MapFilledIcon,
} from '@flock/shared-ui'
import { Core_TransactionType } from '@flock/flock-gql-server/src/__generated__/graphql'
import { getHoldingPeriod } from '@flock/utils/src/money/flexibleCashFlowCalculator'
import OfferDetails from './OfferDetails'
import OfferDocuments from './OfferDocuments/OfferDocuments'
import OfferPagePaper from './OfferPagePaper'
import OfferPageSection from './OfferPageSection'

import { OfferPageData } from './offerPageTypes'

import { scrollIntoView, useInView } from '../utils'

import OfferPageSidebar from './OfferPageSidebar/OfferPageSidebar'
import OfferExchange from './OfferExchange/OfferExchange'
import OfferQuestions from './OfferQuestions/OfferQuestions'
import FlexibleCashflowCalculator from './FlexibleCashflowCalculator/FlexibleCashflowCalculator'
import PortfolioHomeDetails from './PortfolioHomeDetails/PortfolioHomeDetails'
import FloatingMessage from '../OfferPageComponents/FloatingMessage'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

type OfferPageProps = {
  pageData: OfferPageData
}

const OfferPage = (props: OfferPageProps) => {
  const { pageData } = props

  const {
    zipcode,
    cityStateZipcode,
    latitude,
    longitude,
    portfolioBreakdownCsv,
    propertyCondition,
    scheduleInspectionRequested,
    contributionAgreementRequested,
    mortgage,
    hideValuation,
    finalOffer,
    uwCashOnCashYield,
    hideNetYield,
    prelimOffer,
    offerLow,
    offerHigh,
    expiryDate,
    updateScheduleInspectionRequested,
    updateContributionAgreementRequested,
    updateRefreshOfferRequested,
    refreshOfferRequested,
    saamValuationType,
    street,
    slackThreadUrl,
    inspectionDate,
    staticDocumentOverrides,
    leads,

    preinspectionSurveyCompleted,
    transactionType,
    isOverriddenAddress,
    fullName,
    equityOverride,
    cashTakeout,
    shareCount,
    equityAmount,
    showCashFlowDeductions,
    onboardingFee,
    brokerCommission,
    daysInRemodelDeduction,
    closingCosts,
    rentReduction,
    customerDocuments,
    holdingPeriodOverride,
    customerStage,
    customerStatus,
    isFinalValuation,
    hideRemodelCosts,
  } = pageData

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const offerPortfolioText = isMobile
    ? 'Offers are based on sale comps, condition, and market data.'
    : 'Flock offers are based on sale comps, property condition, and market rent data.'

  const [selectedSection, setSelectedSection] = useState(0)
  const [sidebarHeight, setSidebarHeight] = useState(0)
  const sidebarRef = useCallback((domElement: HTMLDivElement) => {
    if (domElement !== null) {
      setSidebarHeight(domElement.clientHeight)
    }
  }, [])

  const sectionIds = useMemo(
    () =>
      shareCount && shareCount > 0
        ? ['offer', 'details', 'exchange', 'earnings', 'questions', 'documents']
        : ['offer', 'details', 'earnings', 'questions', 'documents'],
    [shareCount]
  )

  const currentSection = useInView(sectionIds)

  const sidebarItems = [
    {
      title: 'Offer',
      unselectedIcon: StarOutlinedIcon,
      selectedIcon: StarFilledIcon,
      id: 'offer',
    },
    {
      title: 'Details',
      unselectedIcon: HouseOutlinedIcon,
      selectedIcon: HouseFilledIcon,
      id: 'details',
    },
    {
      title: 'Exchange',
      unselectedIcon: MapIcon,
      selectedIcon: MapFilledIcon,
      id: 'exchange',
      hide: !shareCount || shareCount < 0,
    },
    {
      title: 'Investment',
      unselectedIcon: ChartOutlinedIcon,
      selectedIcon: ChartFilledIcon,
      id: 'earnings',
    },
    {
      title: 'FAQs',
      unselectedIcon: QuestionOutlinedIcon,
      selectedIcon: QuestionFilledIcon,
      id: 'questions',
    },
    {
      title: 'Documents',
      unselectedIcon: DocumentsIcon,
      selectedIcon: DocumentsFilledIcon,
      id: 'documents',
    },
  ]

  const onChangeSidebar = (newTab: number) => {
    scrollIntoView(sectionIds[newTab])
  }

  useEffect(() => {
    setSelectedSection(sectionIds.indexOf(currentSection))
  }, [currentSection, sectionIds])

  // if the lead only provided a full name, remove the "Unknown" part
  const displayFullName = fullName?.replace(' Unknown', '')

  return (
    <>
      <OfferPageSidebar
        sidebarRef={sidebarRef}
        sidebarHeight={sidebarHeight}
        leadStatus={customerStatus}
        sidebarItems={sidebarItems}
        onChangeSidebar={onChangeSidebar}
        selectedSection={selectedSection}
      />
      <OfferPageSection
        name="offer-details"
        backgroundColor="trustBlue.main"
        id="offer"
      >
        <OfferDetails
          name={displayFullName}
          uwCashOnCashYield={uwCashOnCashYield}
          street={street}
          equityAmount={equityAmount}
          zipcode={zipcode}
          cityStateZipcode={cityStateZipcode}
          expiryDate={expiryDate}
          latitude={latitude}
          longitude={longitude}
          hideValuation={hideValuation}
          finalOffer={finalOffer}
          prelimOffer={prelimOffer}
          showRangedValuation={!isFinalValuation}
          offerLow={offerLow}
          offerHigh={offerHigh}
          preinspectionSurveyCompleted={preinspectionSurveyCompleted}
          propertyCondition={propertyCondition}
          leadStage={customerStage}
          isOverriddenAddress={isOverriddenAddress}
          saamValuationType={saamValuationType}
          leadStatus={customerStatus}
          inspectionDate={inspectionDate}
          transactionType={transactionType}
          scheduleInspectionRequested={scheduleInspectionRequested}
          updateScheduleInspectionRequested={updateScheduleInspectionRequested}
          contributionAgreementRequested={contributionAgreementRequested}
          updateContributionAgreementRequested={
            updateContributionAgreementRequested
          }
          updateRefreshOfferRequested={updateRefreshOfferRequested}
          refreshOfferRequested={refreshOfferRequested}
        />
      </OfferPageSection>
      <OfferPageSection
        name="home-details"
        backgroundColor="trustBlue.main"
        id="details"
        shouldHide={
          isOverriddenAddress &&
          transactionType !== Core_TransactionType.TransactionTypePortfolio_721
        }
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={isMobile ? '16px' : '24px'}
        >
          <Typography color="moneyGreen.main" variant="cta">
            {offerPortfolioText}
          </Typography>
          <OfferPagePaper>
            <PortfolioHomeDetails
              leads={leads}
              portfolioCsvUrl={portfolioBreakdownCsv}
              isFinalValuation={isFinalValuation}
              hideRemodelCosts={hideRemodelCosts}
            />
          </OfferPagePaper>
        </Box>
      </OfferPageSection>
      <OfferPageSection
        name="appreciation-potential"
        backgroundColor="trustBlue.main"
        id="exchange"
      >
        <Box
          pb={isMobile ? '16px' : '24px'}
          display="flex"
          gap="32px"
          flexDirection="column"
        >
          <Typography variant="cta" color="moneyGreen.main">
            You receive ownership in Flock&apos;s Fund based on the value of
            your homes.
          </Typography>
        </Box>
        <OfferPagePaper>
          <OfferExchange
            finalOffer={finalOffer || prelimOffer}
            street={street}
            showRangedValuation={!isFinalValuation}
            onboardingFee={onboardingFee}
            closingCosts={closingCosts}
            mortgage={mortgage || 0}
            transactionType={transactionType}
            rentReduction={rentReduction || 0}
            cashTakeout={cashTakeout || 0}
            daysInRemodelDeduction={daysInRemodelDeduction || 0}
            brokerCommission={brokerCommission || 0}
            equityOverride={equityOverride || 0}
          />
        </OfferPagePaper>
      </OfferPageSection>
      {equityOverride
        ? equityOverride > 0
        : equityAmount > 0 && (
            <OfferPageSection
              name="appreciation-potential"
              backgroundColor="trustBlue.main"
              id="earnings"
            >
              <Box
                display="flex"
                flexDirection="column"
                gap={isMobile ? '16px' : '24px'}
              >
                <Typography color="moneyGreen.main" variant="cta">
                  As a Flock Client, you&apos;ll earn income and appreciation
                  from the entire portfolio of homes.
                </Typography>
                <OfferPagePaper>
                  <FlexibleCashflowCalculator
                    initialValuation={finalOffer || prelimOffer}
                    uwCashOnCashYield={uwCashOnCashYield}
                    hideNetYield={hideNetYield}
                    onboardingFee={onboardingFee}
                    rentReduction={rentReduction || 0}
                    daysInRemodelDeduction={daysInRemodelDeduction || 0}
                    mortgageAmount={mortgage || 0}
                    equityOverride={equityOverride || 0}
                    closingCosts={closingCosts || 0}
                    cashTakeout={cashTakeout || 0}
                    showCashFlowDeductions={showCashFlowDeductions}
                    holdingPeriodOverride={holdingPeriodOverride}
                  />
                </OfferPagePaper>
              </Box>
            </OfferPageSection>
          )}
      <OfferPageSection
        name="offer-questions"
        backgroundColor="trustBlue.main"
        id="questions"
      >
        <OfferQuestions />
      </OfferPageSection>
      <OfferPageSection
        name="offer-documents"
        backgroundColor="trustBlue.main"
        id="documents"
      >
        <OfferDocuments
          leadDocuments={customerDocuments || []}
          staticDocumentOverrides={staticDocumentOverrides || {}}
          slackThreadUrl={slackThreadUrl}
        />
      </OfferPageSection>
      {isMobile && (
        <OfferPageSection
          name="floating-cta-mobile"
          backgroundColor="trustBlue.main"
          id="floating-cta-mobile"
        >
          <FloatingMessage />
        </OfferPageSection>
      )}
      <OfferPageSection
        name="offer-disclaimers"
        backgroundColor="trustBlue.main"
        id="disclaimers"
      >
        {/* TODO: Back into uw yield for customer offer pages */}
        {!hideNetYield && uwCashOnCashYield ? (
          <Typography variant="p4" color="gray5.main">
            To benefit from the terms outlined in this offer, it is a
            requirement to maintain your investment in the Fund for at least{' '}
            {holdingPeriodOverride || getHoldingPeriod(uwCashOnCashYield)}{' '}
            years.
            <br />
            <sup>1</sup> This model projects meeting a target annual return of
            10% with annual cash flow. Cash flow includes your{' '}
            {Math.min(Math.round(uwCashOnCashYield * 10000 - 200) / 100, 4)}%
            Annual Cash Flow Allotment + 2% Top Up Allowance. Your capital
            account will be debited 0.10% for every 1% in cash flow you receive
            above your annual allotment. This deduction compensates all other
            clients in the Fund. Taking cash beyond 2-3% annually in the first
            2-3 years of owning Flock may have tax impacts. Actual impacts are
            expected to be limited and generally in the form of capital gains
            tax on the amount of cash exceeding this threshold.
            <br />
          </Typography>
        ) : null}
        <Typography variant="p4" color="gray5.main">
          Performance Disclaimer: In considering the target performance
          information contained herein, prospective investors should bear in
          mind that past or targeted performance is not a guarantee, projection
          or prediction and is not necessarily indicative of future results.
          There can be no assurance that the Fund will achieve comparable
          results, that targeted returns will be met or that the Fund will be
          able to implement its investment strategy and investment approach or
          achieve its investment objectives. Actual gross and net returns for
          the Fund may vary significantly from the targeted returns set forth
          herein.
        </Typography>
      </OfferPageSection>
    </>
  )
}

export default OfferPage
