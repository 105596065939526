import { Box, Typography, Grid, useTheme, useMediaQuery } from '@mui/material'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useMemo } from 'react'
import millify from 'millify'
import { PortfolioMapbox } from '@flock/shared-ui'
import { MAPBOX_TOKEN } from '../../../constants'
import PrelimOfferSection from './PrelimOfferSection'
import BarGraph from '../../../images/bar-graph.svg'
import LineGraph from '../../../images/line-graph.svg'
import PieChart from '../../../images/pie-chart.svg'

const desktopConfig = {
  containerStyle: {
    height: '640px',
    borderRadius: '24px',
  },
  center: [-94.9903, 35.7392],
  startingZoom: 3.4,
}
const mobileConfig = {
  containerStyle: {
    height: '304px',
    borderRadius: '24px',
  },
  center: [-94.9903, 37.7392],
  startingZoom: 0,
}

type StatProps = {
  value: string
  label: string
  src: string
}

export const Stat = ({ src, label, value }: StatProps) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="100%"
    gap={{
      sm: '16px',
      md: '20px',
    }}
    padding={{
      md: '16px 19px 16px 28px',
      sm: '16px 24px',
    }}
    borderRadius="16px"
    sx={{
      boxShadow: 'rgba(69, 73, 77, 0.08)',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
    }}
  >
    {src && (
      <Box
        component="img"
        src={src}
        display={{
          xs: 'none',
          sm: 'block',
        }}
        width={{
          sm: '58px',
          md: '96px',
        }}
        height={{
          sm: '48px',
          md: '80px',
        }}
      />
    )}
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="4px"
      padding={{
        xs: '16px',
        sm: '0px 0px 16px',
        md: '0px 0px 16px',
      }}
    >
      <Typography variant="h1" color="moneyGreen.main">
        {value}
      </Typography>
      <Typography variant="p2">{label}</Typography>
    </Box>
  </Box>
)

type PrelimPortfolioSectionProps = {
  isGeneric: boolean
}

const PrelimPortfolioSection = (props: PrelimPortfolioSectionProps) => {
  const { isGeneric } = props
  const { getPropertySummaryDetails, getDailyMetricsV1 } =
    useStaticQuery(graphql`
      query {
        getPropertySummaryDetails {
          propertyDetails {
            address {
              city
              formattedStreet
              formattedAddress
              latitude
              longitude
              state
              unit
              zipcode
            }
            baths
            beds
            halfBaths
            propertyType
            sqft
            propertyUuid
            yearBuilt
          }
        }
        getDailyMetricsV1 {
          dailyMetrics {
            state
            numProperties
            numOccupiedProperties
            totalSqft
            totalValuation
            totalOccupiedValuation
            totalMonthlyRentCents
            numPropertiesThisQuarter
          }
        }
      }
    `)

  const { propertyDetails } = getPropertySummaryDetails
  const processedAssets = useMemo(
    () =>
      propertyDetails.map((asset: any) => ({
        ...asset,
        ...asset.address,
        baths: asset.baths + (asset.halfBaths / 2 || 0),
      })),
    [propertyDetails]
  )

  const { dailyMetrics } = getDailyMetricsV1
  // defaults to portfolio, which will always be the last item in the array
  const dailyMetric = dailyMetrics[dailyMetrics.length - 1]

  const { totalValuation } = dailyMetric
  const totalValuationNum = parseFloat(totalValuation) / 100

  const marketValueString = millify(totalValuationNum, {
    precision: totalValuationNum >= 100000000 ? 0 : 1,
    lowercase: true,
    space: false,
    units: ['', ' k', ' m', ' b', ' t'],
  }).replace(' ', '')

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <PrelimOfferSection
      sectionId="portfolio"
      nextId="stories"
      nextCta="Flock isn't just about numbers."
    >
      <Grid
        item
        sm={6}
        md={12}
        display="flex"
        flexDirection="column"
        gap={{
          xs: '16px',
          sm: '32px',
          md: '40px',
        }}
        sx={{ paddingTop: isGeneric ? '0px!important' : undefined }}
      >
        {!isGeneric && (
          <Typography
            variant="h2"
            alignSelf="flex-start"
            color="moneyGreen.main"
          >
            Add your property to our growing portfolio.
          </Typography>
        )}
        <Box width="100%">
          <PortfolioMapbox
            propertyAssets={processedAssets}
            mapboxToken={MAPBOX_TOKEN || ''}
            desktopConfig={desktopConfig}
            tabletConfig={desktopConfig}
            mobileConfig={mobileConfig}
          />
        </Box>
        <Box
          width="100%"
          marginTop={{
            sm: '0',
            md: '-256px',
          }}
          zIndex={1}
        >
          <Box
            display="flex"
            padding={{
              sm: '0px',
              md: '24px',
            }}
            gap={{
              xs: '16px',
              sm: '24px',
              md: '24px',
            }}
          >
            <Stat
              src={BarGraph}
              value={propertyDetails.length.toString()}
              label="total homes"
            />
            <Stat
              src={LineGraph}
              value={`$${marketValueString}`}
              label="portfolio value"
            />
            {!isTablet && (
              <Stat src={PieChart} value="$8.6m+" label="annualized revenue" />
            )}
          </Box>
        </Box>
      </Grid>
    </PrelimOfferSection>
  )
}

export default PrelimPortfolioSection
