import {
  LibraryThemeProvider,
  flockTheme,
  PageContainer,
} from '@flock/shared-ui'
import { ThemeProvider } from '@mui/material'
import React from 'react'

type OfferPageWrapperProps = {
  customerUuid: string
  children: React.ReactNode
}

const OfferPageWrapper = (props: OfferPageWrapperProps) => {
  const { children, customerUuid } = props

  return (
    <ThemeProvider theme={flockTheme}>
      <LibraryThemeProvider>
        <PageContainer
          title="Flock Homes | Offer Page"
          trackingName="offer-page"
          trackingProperties={{
            customerUuid,
          }}
        >
          {children}
        </PageContainer>
      </LibraryThemeProvider>
    </ThemeProvider>
  )
}

export default OfferPageWrapper
